* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	text-decoration: none;
	outline: none;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.header {
	height: 60px;
	background-color: #010F20;
	/* overflow: hidden; */
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
}

.header .logo {
	padding: 3px;
	padding-left: 8px;
	display: inline-block;
	height: 60px;
	vertical-align: top;
	width: 130px;
}

.header .logo img {
	height: 52px;
	max-width: 130px;
}

.header .topMenu {
	height: 60px;
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 130px);
	text-align: right;
}




/* unselectable */

.unselectable {
	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
}


/* signin & signup box centered */
.container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: #F0F4F3;
}

.box {
	width: 800px;
	height: 475px;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.2);
}

.column { 
	flex: 1;
	padding: 10px;
	height: 100%;
}


/*Signin Page */
.column.signin.column-1 {
	background-color: #ffffff;
	flex-basis: 62%;
}

.column.signin.column-2 {
	background-color: #3AB397;
	flex-basis: 38%;
}

.column.signup.column-1 {
	background-color: #3AB397;
	flex-basis: 38%;
}

.column.signup.column-2 {
	background-color: #ffffff;
	flex-basis: 62%;
}

.column.signin.column-1 .logo {
	height: 50px;
	background-image: url(/src/Images/logo/logo-color.svg);
	background-repeat: no-repeat;
	background-position: center left;
	background-size: 50px;
}

.column.signin.column-1 .content {
	height: calc(100% - 50px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.column.signin.column-1 .content .form {
	width: 300px;
}

.column.signin.column-1 .content .title {
	font-size: x-large;
	color: #3AB397;
	font-weight: bold;
	display: block;
	text-align: center;
	margin-bottom: 20px;
}

.column.signin.column-1 .content .googleLogin{
	background-color: #ffffff;
	border: 1px solid #eeeeee;
	height: 40px;
	width: 100%;
	background-image: url(/src/Images/icons/google.svg);
	background-size: 25px;
	background-repeat: no-repeat;
	background-position: 10px center;
	border-radius: 5px;
	cursor: pointer;
	font-weight: bold;
	color: #666666;
	margin: 5px 0;
}
.googleLogin:hover {
	opacity: 0.8;
}

.column.signin.column-1 .content input[type=text],
.column.signin.column-1 .content input[type=password] {
	background-color: #F0F4F3;
	border: 0px solid #eeeeee;
	height: 40px;
	width: 100%;
	margin: 5px 0;
	padding: 0 10px;
	padding-left: 30px;
	border-radius: 5px;
	background-size: 14px;
	background-repeat: no-repeat;
	background-position: 10px center;
	color: #333333;
}

.column.signin.column-1 .content input[type=text].username {
	background-image: url('data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IlNWR1JlcG9fYmdDYXJyaWVyIiBzdHJva2Utd2lkdGg9IjAiLz4KPGcgaWQ9IlNWR1JlcG9fdHJhY2VyQ2FycmllciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZD0iTTE2IDEyQzE2IDE0LjIwOTEgMTQuMjA5MSAxNiAxMiAxNkM5Ljc5MDg2IDE2IDggMTQuMjA5MSA4IDEyQzggOS43OTA4NiA5Ljc5MDg2IDggMTIgOEMxNC4yMDkxIDggMTYgOS43OTA4NiAxNiAxMlpNMTYgMTJWMTMuNUMxNiAxNC44ODA3IDE3LjExOTMgMTYgMTguNSAxNlYxNkMxOS44ODA3IDE2IDIxIDE0Ljg4MDcgMjEgMTMuNVYxMkMyMSA3LjAyOTQ0IDE2Ljk3MDYgMyAxMiAzQzcuMDI5NDQgMyAzIDcuMDI5NDQgMyAxMkMzIDE2Ljk3MDYgNy4wMjk0NCAyMSAxMiAyMUgxNiIgc3Ryb2tlPSIjODg4ODg4IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPiA8L2c+Cjwvc3ZnPg==');
}
.column.signin.column-1 .content input[type=text].username:focus {
	background-image: url(/src/Images/icons/email-active.svg);
}

.column.signin.column-1 .content input[type=password].password {
	background-image: url(/src/Images/icons/password-iddle.svg);
}
.column.signin.column-1 .content input[type=password].password:focus {
	background-image: url(/src/Images/icons/password-active.svg);
}

.loginBtn{
	background-color: #3AB397;
	border: 0;
	height: 40px;
	width: 100%;
	margin: 5px 0;
	border-radius: 5px;
	color:#ffffff;
}

.loginBtn:enabled{
	cursor: pointer;
}

.loginBtn:enabled:hover{
	opacity: 0.8;
}
.loginDivider{
	padding: 10px;
	text-align: center;
	color: #888888;
	font-size: small;
}
.forgotPasswordLink {
	text-align: right;
	margin-bottom: 7px;
	padding: 0px;
}
.forgotPasswordLink a {
	font-size: small;
	color: #666666;
	border-bottom: 1px dashed #cccccc;
	padding: 2px;
}

.forgotPasswordLink a:hover{
	border-bottom: 1px dashed #3AB397;
}


.column.signin.column-2 .content {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.column.signin.column-2 .content .form {
	width: 100%;
	text-align: center;
	padding: 20px;
}


.column.signin.column-2 .content .form .title {
	color: #ffffff;
	display: block;
	margin-bottom: 20px;
}

.column.signin.column-2 .content .form .caption {
	color: #ffffff;
	display: block;
	margin-bottom: 50px;
}

.column.signin.column-2 .content .form .signupBtn {
	color: #ffffff;
	background-color: #3AB397;
	border: 1px solid #ffffff;
	width: 150px;
	height: 40px;
	border-radius: 40px;
	font-weight: bold;
}
.column.signin.column-2 .content .form .signupBtn:enabled {
	cursor: pointer;
}
.column.signin.column-2 .content .form .signupBtn:enabled:hover {
	border-style: dashed;
}





/* Signup Page */
.column.signup.column-1 .logo {
	height: 50px;
	background-image: url(/src/Images/logo/logo-white.svg);
	background-repeat: no-repeat;
	background-position: center left;
	background-size: 50px;
}
.column.signup.column-1 .content {
	height: calc(100% - 50px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.column.signup.column-1 .content .form {
	width: 100%;
	text-align: center;
	padding: 10px;
}


.column.signup.column-1 .content .form .title {
	color: #ffffff;
	display: block;
	margin-bottom: 20px;
}

.column.signup.column-1 .content .form .caption {
	color: #ffffff;
	display: block;
	margin-bottom: 50px;
}

.column.signup.column-1 .content .form .signinBtn {
	color: #ffffff;
	background-color: #3AB397;
	border: 1px solid #ffffff;
	width: 150px;
	height: 40px;
	border-radius: 40px;
	font-weight: bold;
}
.column.signup.column-1 .content .form .signinBtn:enabled {
	cursor: pointer;
}
.column.signup.column-1 .content .form .signinBtn:enabled:hover {
	border-style: dashed;
}



.column.signup.column-2 .content {
	height: calc(100% - 50px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.column.signup.column-2 .content .form {
	width: 300px;
}

.column.signup.column-2 .content .title {
	font-size: x-large;
	color: #3AB397;
	font-weight: bold;
	display: block;
	text-align: center;
	margin-bottom: 20px;
}

.column.signup.column-2 .content .googleLogin{
	background-color: #ffffff;
	border: 1px solid #eeeeee;
	height: 40px;
	width: 100%;
	background-image: url(/src/Images/icons/google.svg);
	background-size: 25px;
	background-repeat: no-repeat;
	background-position: 10px center;
	border-radius: 5px;
	cursor: pointer;
	font-weight: bold;
	color: #666666;
	margin: 5px 0;
}
.googleLogin:hover {
	opacity: 0.8;
}

.column.signup.column-2 .content input[type=text],
.column.signup.column-2 .content input[type=password] {
	background-color: #F0F4F3;
	border: 0px solid #eeeeee;
	height: 40px;
	width: 100%;
	margin: 5px 0;
	padding: 0 10px;
	padding-left: 30px;
	border-radius: 5px;
	background-size: 14px;
	background-repeat: no-repeat;
	background-position: 10px center;
	color: #333333;
}

.column.signup.column-2 .content input[type=text].fullname {
	background-image: url(/src/Images/icons/user-iddle.svg);
}
.column.signup.column-2 .content input[type=text].fullname:focus {
	background-image: url(/src/Images/icons/user-active.svg);
}

.column.signup.column-2 .content input[type=text].username {
	background-image: url(/src/Images/icons/email-iddle.svg);
}
.column.signup.column-2 .content input[type=text].username:focus {
	background-image: url(/src/Images/icons/email-active.svg);
}



.column.signup.column-2 .content input[type=password].password {
	background-image: url(/src/Images/icons/password-iddle.svg);
}
.column.signup.column-2 .content input[type=password].password:focus {
	background-image: url(/src/Images/icons/password-active.svg);
}

.signupBtn{
	background-color: #3AB397;
	border: 0;
	height: 40px;
	width: 100%;
	margin: 5px 0;
	border-radius: 5px;
	color:#ffffff;
}

.signupBtn:enabled{
	cursor: pointer;
}

.signupBtn:enabled:hover{
	opacity: 0.8;
}
.signupDivider{
	padding: 10px;
	text-align: center;
	color: #888888;
	font-size: small;
}
